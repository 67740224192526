@import '../../styles/customMediaQueries.css';

.landing__page {
  & .authenticated #host-most .BlockDefault_text__uquKZ .Link_link__lXwyG {
    display: none;
  }
  & #hero {
    & .SectionContainer_sectionContent__vqzb1 {
      min-height: calc(75vh - 72px);

      @media (--viewportLargeIpad) {
        min-height: calc(100vh - 72px);
      }
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .SectionBuilder_blockContainer__M5X1Y {
      width: 100%;
      gap: 10px;
      padding: 0 16px;
      align-self: flex-end;
      padding-bottom: 20px;
      position: relative;
      top: -60px;
      @media (--viewportLargeIpad) {
        top: -20px;
        align-self: unset;
        padding-bottom: 0;
      }
    }
  }
  & #below-header-menus {
    & .SectionContainer_sectionContent__vqzb1 {
      max-width: var(--contentMaxWidth);
      margin: 0 auto;
      & .SectionBuilder_blockContainer__M5X1Y {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        column-gap: 20px;
        position: relative;
        padding: 0 16px;
        gap: 0;
        @media (--viewportLargeIpad) {
          padding-top: 0;
          padding-left: 51%;
          margin: 62px 0px;
        }
        @media (--viewportLargeWithPaddings) {
          padding-left: 52%;
          margin: 0px 0px;
        }

        & > div {
          &:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 10px;

            @media (--viewportLargeIpad) {
              margin-bottom: 0;
              flex-direction: column;
              align-items: flex-start;
              position: absolute;
              left: 0;
              right: unset;
              top: 50%;
              transform: translateY(-50%);
              padding: 0;
              padding-left: 16px;
            }
            @media (--viewportLargeIpad) {
              max-width: 50%;
            }
            & .BlockDefault_text__uquKZ {
              margin: 0;
              display: flex;
              flex-direction: column;

              & .Heading_h2__rNLtP,
              & > h2 {
                font-weight: 900;
                font-size: 30px;
                line-height: 38px;

                @media (--viewportMedium) {
                  font-size: 35px;
                  line-height: 38px;
                }
                @media (--viewportLargeIpad) {
                  font-size: 45px;
                  line-height: 48px;
                }
              }

              & > p {
                display: block;
                font-size: 16px;
                line-height: 22px;
                text-align: left;
              }

              & > ul{
                padding-left: 20px;
                display: grid;
                grid-template-columns: repeat(2,50%);
                grid-row-gap: 10px;
                grid-column-gap: 20px;
                margin-bottom: 15px;
                margin-top: 15px;
                font-size: 15px;

                @media (--viewportLargeWithPaddings) {
                  grid-template-columns: repeat(3,31%);
                  grid-row-gap: 0px;
                }

                @media (--viewportMedium) {
                  & > p {
                    text-align: justify;
                  }
                }
                
                
                @media (--viewportLargeIpad) {
                  font-size: 16px;
                }
              }
            }
            & .BlockDefault_media__Q48A5 {
              display: block;
              background: none;
              margin-left: -5px;
              margin-bottom: 5px;
              max-width: 175px;
              @media (--viewportSmall) {
                max-width: 220px;
              }
            }
            & .SectionBuilder_ctaButton__gOaZN {
              width: 100%;
              max-width: 100%;
              margin: 15px 0 0;
              background-color: var(--marketplaceColor);
              font-size: 15px;
              font-weight: bold;
              min-height: 42px;
              padding: 0 20px;
              line-height: 42px;
              text-align: left;
              position: relative;
              text-decoration: none;
              box-shadow: none;
              max-width: 230px;
              @media (--viewportLarge) {
                font-size: 18px;
                margin: 15px 0 0;
              }

              &::before {
                content: '';
                position: absolute;
                right: 20px;
                top: 50%;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 8.5px 0 8.5px 12px;
                border-color: transparent transparent transparent #000;
                transform: rotate(0deg) translateY(-50%);
              }
            }
          }
          &:not(:first-child) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 5px;
            width: 100%;
            margin-right: 5px;
            margin-bottom: 5px;
            position: relative;
            margin: 0;
            justify-content: flex-start;

            @media (--viewportMedium) {
              margin-right: 10px;
              margin-bottom: 50px;
              width: 100%;
            }

            @media (--viewportLargeIpad) {
              margin-bottom: 10px;
            }

            
            & .SectionBuilder_ctaButton__gOaZN {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              opacity: 0;
              padding: 5px;
            }
            & .BlockDefault_media__Q48A5 {
              background-color: transparent;
              margin-right: 5px;
              flex: 0 1;

              & .AspectRatioWrapper_root__8ihQp {
                width: 100%;
                height: 100%;
                @media (--viewportMedium) {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            & .BlockDefault_text__uquKZ {
              margin: 0;
              & .Heading_h3__U-efn {
                font-size: 13px;
                line-height: 18px;
                @media (--viewportSmall) {
                  font-size: 14px;
                  line-height: 21px;
                }
                color: var(--matterColorAnti);
                text-align: center;
                @media (--viewportMedium) {
                  font-size: 14px;
                }
                font-weight: 900;
              }
            }
          }
        }
      }
    }
  }
  & #professional-services {
    & .SectionContainer_sectionContent__vqzb1 {
      padding: 5px 0 60px;
      @media (--viewportMedium) {
        padding: 60px 0;
      }
    }
    & .SectionBuilder_blockContainer__M5X1Y {
      flex-direction: column;
      display: flex;
      justify-content: flex-start;
      column-gap: 0;
      gap: 0;
      padding: 0 16px;
      @media (--viewportMedium) {
        flex-direction: row;
      }
      & .BlockDefault_media__Q48A5 {
        background-color: transparent;
      }
      & .BlockContainer_root__9JaaU {
        & .Heading_h2__rNLtP {
          font-weight: 900;
          font-size: 30px;
          line-height: 38px;

          @media (--viewportMedium) {
            font-size: 35px;
            line-height: 38px;
          }
          @media (--viewportLargeIpad) {
            font-size: 45px;
            line-height: 48px;
          }
        }

        & .P_p__qMuDQ {
          font-size: 14px;
          line-height: 19px;
          text-align: left;
          padding-right: 0px;
          @media (--viewportLargeIpad) {
            font-size: 16px;
            line-height: 22px;
          }

          @media (--viewportMedium) {
            text-align: justify;
            padding-right: 30px;
          }
          font-weight: 400;
        }
        &:nth-child(1) {
          padding: 15px 0 32px;
          flex: 0 0 60%;
          @media (--viewportMedium) {
            padding: 30px 0;
          }
          @media (--viewportLargeIpad) {
            flex: 0 0 42%;
          }
        }
        &:nth-child(2) {
          display: flex;
          align-items: center;
          flex: 1;
          padding: 30px 0;
          width: 100%;
          order: -1;
          /* margin: 0 auto; */
          @media (--viewportMedium) {
            padding: 0 0 0 15px;
            order: unset;
          }
        }
      }
    }
  }
  & #call-to-actions {
    & .SectionContainer_sectionContent__vqzb1 {
      padding: 30px 0 40px;
      @media (--viewportMedium) {
        padding: 100px 0;
      }
    }
    & .SectionBuilder_blockContainer__M5X1Y {
      padding: 0 16px;
      max-width: 360px;
      margin: 0 auto;
      gap: 16px;
      @media (--viewportSmall) {
        max-width: 440px;
      }
      @media (--viewportMedium) {
        gap: 32px;
        max-width: var(--contentMaxWidth);
      }
      & .BlockContainer_root__9JaaU {
        position: relative;
        padding: 10px 0;
        & .BlockDefault_media__Q48A5 .Image_fieldImage__iz9Zc {
          border-radius: 0;
        }
        & .BlockDefault_text__uquKZ {
          margin-top: 16px;
          & .Heading_h3__U-efn {
            font-size: 19px;
            line-height: 27px;
            font-weight: 900;
          }
          & .P_p__qMuDQ {
            font-size: 13px;
            font-weight: 600;
            margin: 0;
            line-height: 15px;
          }
          & .Link_link__lXwyG {
            position: absolute;
            top: 0;
            margin: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
          }
        }
      }
    }
  }
  & #host-most {
    position: relative;
    & .CustomAppearance_backgroundImageWrapper__BzwmF {
      display: none;
      @media (--viewportMedium) {
        display: block;
      }
      & img {
        object-position: right;
        @media (--viewportMedium) {
          object-position: unset;
        }
      }
    }

    & .SectionContainer_sectionContent__vqzb1 {
      padding: 0;

      @media (--viewportMedium) {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0.6;
          background-color: #000;
          z-index: 1;

          @media (--viewportMedium) {
            right: 45%;
            opacity: 0.8;
          }
        }
      }
      & .SectionBuilder_blockContainer__M5X1Y {
        align-items: end;
        @media (--viewportMedium) {
          align-items: center;
        }
      }
    }
    & .SectionColumns_baseColumn__xpuAr {
      align-items: center;
      padding: 0 0;
      min-height: 60vh;
      gap: 0;
      @media (--viewportMedium) {
        gap: 32px;
        min-height: 50vh;
      }
      @media (--viewportLarge) {
        min-height: 95vh;
      }
      & .BlockContainer_root__9JaaU {
        &:first-child {
        }
        &:last-child {
          padding: 0;
          order: -1;
          @media (--viewportMedium) {
            display: none;
          }
        }
        position: relative;
        z-index: 2;
        padding: 30px 16px;
        background-color: #000;
        @media (--viewportMedium) {
          background-color: transparent;
        }
        & .Heading_h3__U-efn {
          font-size: 24px;
          line-height: 32px;
          @media (--viewportLarge) {
            font-size: 38px;
            line-height: 42px;
          }
        }
        & .P_p__qMuDQ {
          font-size: 14px;
          line-height: 19px;
          margin-top: 15px;
          text-align: left;
          @media (--viewportMedium) {
            text-align: justify;
          }
          @media (--viewportLarge) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
  &
    #professional-services
    .SectionBuilder_blockContainer__M5X1Y
    .BlockContainer_root__9JaaU
    .SectionBuilder_ctaButton__gOaZN,
  & #host-most .SectionColumns_baseColumn__xpuAr .SectionBuilder_ctaButton__gOaZN {
    width: 100%;
    border-radius: 0;
    max-width: 220px;
    margin-top: 30px;
    box-shadow: none;
    font-size: 16px;
    @media (--viewportMedium) {
      font-size: 18px;
    }
    font-weight: bold;
    min-height: 40px;
    padding: 0 20px;
    line-height: 40px;
    text-align: left;
    position: relative;
  }
  &
    #professional-services
    .SectionBuilder_blockContainer__M5X1Y
    .BlockContainer_root__9JaaU
    .SectionBuilder_ctaButton__gOaZN::before,
  & #host-most .SectionColumns_baseColumn__xpuAr .SectionBuilder_ctaButton__gOaZN::before {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 8.5px 0 8.5px 12px;
    border-color: transparent transparent transparent #000;
    transform: rotate(0deg) translateY(-50%);
  }
}
